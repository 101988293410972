<template>

  <button class="btn btn-soft-info w-100 mb-2 visually-hidden" variant="secondary"
          type="button" data-bs-toggle="offcanvas" data-bs-target="#viewTrain"
          aria-controls="createTrain" id="viewTrainCanvasToggleBtn">
    View New Train
  </button>

  <div class="offcanvas offcanvas-end" tabindex="-1" id="viewTrain" aria-labelledby="viewTrainLabel">
    <div class="offcanvas-header">
      <h5 id="viewTrainLabel">Train View</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body pt-2" v-if="Object.keys(train_computed).length > 0">
      <form>
        <div class="row mb-3">
          <div class="col-lg-3">
            <label for="nameInput" class="form-label">Name</label>
          </div>
          <div class="col-lg-9 text-end">
            <router-link :to="{name: 'trains_profiles', params: {train_slug: train_computed.slug}}">
              {{ train_computed.title }}
            </router-link>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-3">
            <label class="form-label">Type</label>
          </div>
          <div class="col-lg-9 text-end">
            {{ train_computed.type }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-3">
            <label class="form-label">Status</label>
          </div>
          <div class="col-lg-9 text-end">
            {{ train_computed.status }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-3">
            <label class="form-label">Color</label>
          </div>
          <div class="col-lg-9 text-end">
            {{ train_computed.color[0] }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-3">
            <label class="form-label">Route</label>
          </div>
          <div class="col-lg-9 text-end">
            {{ train_computed.route }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-3">
            <label class="form-label">Start Date</label>
          </div>
          <div class="col-lg-9 text-end">
            {{ train_computed.date.split(' to ')[0] }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-3">
            <label class="form-label">End Date</label>
          </div>
          <div class="col-lg-9 text-end">
            {{ train_computed.date.split(' to ')[1] }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-3">
            <label class="form-label">Departure</label>
          </div>
          <div class="col-lg-9 text-end">
                            <span v-if="train_computed.departure_station">
                                {{ train_computed.departure_station.code }},
                                {{ train_computed.departure_station.name }}
                            </span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-3">
            <label class="form-label">Destination</label>
          </div>
          <div class="col-lg-9 text-end">
                            <span v-if="train_computed.destination_station">
                                {{ train_computed.destination_station.code }},
                                {{ train_computed.destination_station.name }}
                            </span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-3">
            <label class="form-label">Description</label>
          </div>
          <div class="col-lg-9 text-end">
            {{ train_computed.description }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6 text-end">
            <router-link :to="{name: 'trains_profiles', params: {train_slug: train_computed.slug}}">
              <b-button class="w-100">
                View
                <i class="mdi mdi-eye ms-1"></i>
              </b-button>
            </router-link>
          </div>
          <div class="col-6 text-end">
            <router-link :to="{name: 'train_details_settings', params: {train_slug: train_computed.slug}}">
              <b-button class="w-100" variant="success">
                Edit
                <i class="mdi mdi-pencil ms-1"></i>
              </b-button>
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
export default {
  name: "train_view_edit",
  props: {
    train: {
      type: Object,
      required: false
    }
  },
  data() {
    return {}
  },
  computed: {
    train_computed() {
      return this.train
    }
  },
}
</script>

<style scoped>

</style>