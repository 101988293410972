<script>
import PageHeader from "@/components/page-header";
import TrainCreate from './CreateTrain.vue'
import TrainCalendar from './TrainCalendar.vue'


export default {
  page: {
    title: "Calendar",
    meta: [{name: "description", content: ''}],
  },
  data() {
    return {
      title: "Calendar",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Train Calendar",
          active: true,
        },
      ],
      clicked_date: new Date(),
      getUpdate: false,
      show_create_train: false,
    };
  },
  components: {
    PageHeader,
    TrainCreate,
    TrainCalendar
  },
};
</script>

<template>
  <PageHeader :title="title" :items="items"/>

  <b-row>
    <b-col xl="12">
      <TrainCreate :clicked_date="clicked_date" @trainCreated="getUpdate = !getUpdate"/>
      <TrainCalendar @dateClicked="clicked_date = $event" :getUpdate="getUpdate"/>
    </b-col>
  </b-row>

</template>