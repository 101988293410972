<template>

  <b-card no-body class="mb-0 py-0 rounded-0">
    <b-card-body class="rounded-0 pb-0">
      <button class="btn btn-soft-info w-100 mb-2" variant="secondary"
              type="button" data-bs-toggle="offcanvas" data-bs-target="#createTrain"
              aria-controls="createTrain" id="createTrainCanvasToggleBtn">
        Schedule New Train
      </button>
    </b-card-body>
  </b-card>

  <div class="offcanvas offcanvas-end" tabindex="-1" id="createTrain" aria-labelledby="createTrainLabel">
    <div class="offcanvas-header">
      <h5 id="createTrainLabel">Schedule New Train</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body pt-2">
      <form @submit.prevent="createTrain">
        <div class="row train-form">

          <div class="col-12">
            <div class="mb-3">
              <label class="form-label">Name</label>
              <input id="name" v-model="train.name" type="text" class="form-control"
                     placeholder="Name"
                     :class="{ 'is-invalid': submitted && v$.train.name.$error }"
              />
              <div v-if="submitted && v$.train.name.$error" class="invalid-feedback">
                                <span v-if="v$.train.name.required.$message">
                                    {{ v$.train.name.required.$message }}
                                </span>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="mb-3">
              <label class="form-label">Group</label>
              <input id="group" type="text" class="form-control visually-hidden"
                     placeholder="Name"
              />
              <Multiselect v-model="train.group_id" v-bind="groupIdSelect"/>

            </div>
          </div>

          <div class="col-12">
            <div class="mb-3">
              <label class="form-label">Type</label>
              <select id="type" v-model="train.train_type" class="form-control"
                      :class="{ 'is-invalid': submitted && v$.train.train_type.$error }">
                <option v-for="option in types" :key="option" :value="option.value">
                  {{ option.name }}
                </option>
              </select>
              <div v-if="submitted && v$.train.train_type.$error" class="invalid-feedback">
                        <span v-if="v$.train.train_type.required.$message">
                            {{ v$.train.train_type.required.$message }}
                        </span>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="mb-3">
              <label class="form-label">Status</label>
              <select id="type" v-model="train.status" class="form-control"
                      :class="{ 'is-invalid': submitted && v$.train.status.$error }">
                <option v-for="option in statuses" :key="option" :value="option.value">
                  {{ option.name }}
                </option>
              </select>
              <div v-if="submitted && v$.train.status.$error" class="invalid-feedback">
                        <span v-if="v$.train.status.required.$message">
                            {{ v$.train.status.required.$message }}
                        </span>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="mb-3">
              <label class="form-label">Color</label>
              <select id="type" v-model="train.color" class="form-control"
                      :class="{
                                    'is-invalid': submitted && v$.train.color.$error,
                                    'text-primary': train.color === 'bg-primary',
                                    'text-success': train.color === 'bg-success',
                                    'text-danger': train.color === 'bg-danger',
                                    'text-secondary': train.color === 'bg-secondary',
                                    'text-info': train.color === 'bg-info',
                                    'text-dark': train.color === 'bg-dark',
                                    'text-warning': train.color === 'bg-warning',
                                }"
              >
                <option v-for="option in categories" :key="option"
                        :class="'text-' + option.value" :value="option.value">
                  {{ option.name }}
                </option>
              </select>
              <div v-if="submitted && v$.train.color.$error" class="invalid-feedback">
                                    <span v-if="v$.train.color.required.$message">
                                        {{ v$.train.color.required.$message }}
                                    </span>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="mb-3">
              <label class="form-label">Route</label>
              <input id="route" v-model="train.route" type="text" class="form-control"
                     placeholder="Route"
                     :class="{ 'is-invalid': submitted && v$.train.route.$error }"
              />
              <div v-if="submitted && v$.train.route.$error" class="invalid-feedback">
                        <span v-if="v$.train.route.required.$message">
                            {{ v$.train.route.required.$message }}
                        </span>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="mb-3">
              <label>ETD ~ ETA</label>
              <div class="input-group">
                <div class="w-100"
                     :class="{ 'border border-danger rounded-2': submitted && v$.train.date.$error }">
                  <flat-pickr placeholder="Select date"
                              :config="timeConfig"
                              v-model="train.date"
                              class="form-control flatpickr-input w-100"
                  >
                  </flat-pickr>
                </div>
                <div v-if="submitted && v$.train.date.$error" class="invalid-feedback">
                                    <span v-if="v$.train.date.required.$message">
                                        {{ v$.train.date.required.$message }}
                                    </span>
                </div>
              </div>
            </div>
          </div>

          <SelectStations @onSelect="onStationSelected" :ratio="[6,6,6,6]"/>

          <div class="col-12">
            <div class="mb-3">
              <label class="form-label">Description</label>
              <textarea class="form-control d-block" v-model="train.description"
                        placeholder="Enter a description" rows="3" spellcheck="false"
                        :class="{ 'is-invalid': submitted && v$.train.description.$error }">
                            </textarea>
              <div v-if="submitted && v$.train.description.$error" class="invalid-feedback">
                <span v-if="v$.train.description.required.$message">{{
                    v$.train.description.required.$message
                  }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-end pt-3">
          <b-button variant="light">Close</b-button>
          <b-button type="submit" variant="success" class="ms-1">Schedule train</b-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import SelectStations from "@/components/custom/SelectStations.vue"
import {categories, types, statuses} from "../utils";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Swal from "sweetalert2";

import moment from "moment";
import axios from "axios";

import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default {
  emits: ["trainCreated"],
  name: "create_train",
  props: {
    clicked_date: {
      type: Date,
      required: false
    }
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations: {
    train: {
      name: {
        required: helpers.withMessage("Name is required", required),
      },
      train_type: {
        required: helpers.withMessage("Type is required", required),
      },
      route: {
        required: helpers.withMessage("Route is required", required),
      },
      color: {
        required: helpers.withMessage("Color is required", required),
      },
      status: {
        required: helpers.withMessage("Status is required", required),
      },
      date: {
        required: helpers.withMessage("Date is required", required),
      },
      description: {
        required: helpers.withMessage("Description is required", required),
      },
      departure: {
        required: helpers.withMessage("Departure station is required", required),
      },
      destination: {
        required: helpers.withMessage("Destination station is required", required),
      },
    },
  },
  components: {
    SelectStations,
    flatPickr,
    Multiselect
  },
  data() {
    return {
      timeConfig: {
        enableTime: false,
        altInput: true,
        dateFormat: "Z",
        altFormat: "d M, Y",
        mode: "range",
      },
      train: {
        name: "",
        group_id: null,
        train_type: "export",
        route: "TSR",
        color: "bg-primary",
        status: "expecting",
        date: "",
        departure: "",
        destination: "",
        description: "",
      },
      submitted: false,
      categories: categories,
      types: types,
      statuses: statuses,
      groupIdSelect: {
        options: [],
        placeholder: "Select group",
      }
    }
  },
  methods: {
    async createTrain() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        alert('Please fill all required fields')
        return false;
      } else {
        let title = this.train.name;
        let group_id = this.train.group_id;
        let train_type = this.train.train_type;
        let route = this.train.route;
        let status = this.train.status;
        let color = this.train.color;
        let date = this.train.date.split(' to ');
        let departure_station = this.train.departure;
        let destination_station = this.train.destination;
        let description = this.train.description;

        const departure_date = moment(date[0]).add(1, 'day').toISOString().slice(0, 10);
        const expected_arrival_date = moment(date[1]).add(1, 'day').toISOString().slice(0, 10);

        try {
          await axios.post('/train/create/', {
            name: title,
            group_id: group_id,
            type: train_type,
            color: color,
            route: route,
            status: status,
            departure_date: departure_date,
            expected_arrival_date: expected_arrival_date,
            departure_station_id: departure_station.value,
            destination_station_id: destination_station.value,
            description: description,
          })
          this.submitted = false
          this.$emit('trainCreated')
          await Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Your train has been scheduled successfully',
            showConfirmButton: false,
          })
          document.querySelector('#createTrain .btn-close').click();

          this.train.name = ''
          this.train.train_type = 'export'
          this.train.route = ''
          this.train.color = ''
          this.train.status = 'expecting'
          this.train.date = ''
          this.train.description = ''

        } catch (error) {
          let response = error.response.data
          let errors = response.extra.fields
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: Object.values(errors),
          })
        }
      }
    },

    async getGroupOptions() {
      try {
        let response = await axios.get('/third_party/railway/groups/')
        this.groupIdSelect.options = response.data.groups.group.map((option) => {
          return {
            value: option.group_id,
            label: option.group_name,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },

    onStationSelected(event) {
      this.train[event.option] = event.value
    },
  },
  mounted() {
    this.getGroupOptions()
  },
  watch: {
    clicked_date(newDate) {
      let start_date = new Date(newDate);
      let end_date = new Date(start_date.getFullYear(), start_date.getMonth(), start_date.getDate() + 12);
      this.train.date = start_date.toString() + ' to ' + end_date.toString()
    }
  }
}
</script>

<style scoped>

</style>