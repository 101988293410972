<template>
  <TrainView :train="edit_train"/>
  <b-card no-body class="rounded-0">
    <b-card-body class="rounded-0">
      <FullCalendar v-if="!is_loading" :options="calendar_computed"/>
      <div v-else class="text-center py-5 my-4">
        <b-spinner variant="primary"></b-spinner>
        <h6 class="mb-0 mt-3">Loading calendar..</h6>
      </div>
    </b-card-body>
  </b-card>


  <div class="mt-4">
    <h5>Upcoming Trains</h5>
    <span class="text-muted">Don't miss your    scheduled trains!</span>
  </div>
  <swiper
      class="default-swiper rounded mt-2"
      :loop="false"
      :grabCursor="true"
      :slidesPerView="4"
      :spaceBetween="15"
  >
    <swiper-slide v-for="train in calendar_computed.initialEvents" :key="train.id">
      <b-card no-body>
        <b-card-body>
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="card-title">{{ train.title }}</h6>
            <span class="badge" :class="train.classNames">{{ train.type }}</span>
          </div>
          <p class="text-muted">{{ train.description }}</p>
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ new Date(train.start).toISOString().slice(0, 10) }}</span>
            <span>{{ new Date(train.end).toISOString().slice(0, 10) }}</span>
          </div>
        </b-card-body>
      </b-card>
    </swiper-slide>
  </swiper>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import axios from "axios";
import timeGridPlugin from "@fullcalendar/timegrid";

import SwiperCore, {Navigation, Pagination, Thumbs} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Thumbs, Pagination, Navigation]);


import TrainView from './ViewTrain.vue'

export default {
  emits: ["dateClicked"],
  name: "train_calendar",
  props: {
    getUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      is_loading: true,
      trains_list: [],
      calendarOptions: {
        timeZone: "local",
        droppable: true,
        navLinks: true,
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        themeSystem: "bootstrap",
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,listMonth",
        },
        windowResize: () => {
          this.getInitialView();
        },
        initialView: this.getInitialView(),
        initialEvents: [],
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
      },


      edit_train: {}
    }
  },
  components: {
    TrainView,
    FullCalendar,
    // SimpleBar,
    Swiper,
    SwiperSlide,
  },
  computed: {
    calendar_computed() {
      return this.calendarOptions
    }
  },
  mounted() {
    this.getTrains();
  },
  methods: {
    getInitialView() {
      if (window.innerWidth >= 768 && window.innerWidth < 1200) {
        return "timeGridWeek";
      } else if (window.innerWidth <= 768) {
        return "listMonth";
      } else {
        return "dayGridMonth";
      }
    },

    dateClicked(info) {
      this.$emit("dateClicked", info.date);
      document.getElementById('createTrainCanvasToggleBtn').click()
    },

    editEvent(train) {
      this.edit_train = {
        id: train.event.id,
        slug: train.event.extendedProps.slug,
        title: train.event.title,
        date: new Date(train.event.start).toISOString().slice(0, 10) + ' to ' + new Date(train.event.end).toISOString().slice(0, 10),
        color: train.event.classNames,
        description: train.event.extendedProps.description,
        type: train.event.extendedProps.type,
        status: train.event.extendedProps.status,
        route: train.event.extendedProps.route,
        departure_station: train.event.extendedProps.departure_station,
        destination_station: train.event.extendedProps.destination_station,
      }
      document.getElementById('viewTrainCanvasToggleBtn').click()
    },

    async getTrains() {
      this.is_loading = true
      let response = await axios.get('/train/list/')
      this.trains_list = response.data.results

      if (this.trains_list === []) {
        this.calendarOptions.initialEvents = []
      } else {
        this.calendarOptions.initialEvents = this.trains_list.map((train) => {
          let date1 = new Date(train.departure_date)
          let date2 = new Date(train.expected_arrival_date)
          return {
            id: train.id,
            slug: train.slug,
            title: train.name,
            start: date1,
            end: date2,
            classNames: train.color,
            description: train.description,
            type: train.type,
            status: train.status,
            route: train.route,
            departure_station: train.departure_station,
            destination_station: train.destination_station,
          }
        })
      }
      this.is_loading = false
    },
  },
  watch: {
    getUpdate() {
      this.getTrains();
    }
  }
}
</script>

